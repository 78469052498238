<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>اضافة تصنيف جديدة </CCardHeader>
        <CCardBody>
          <CForm>
            <CRow class="mt-3">
              <CCol sm="12" md="9">
                <CInput
                  invalid
                  description=""
                  label="الاسم العربي"
                  horizontal
                  autocomplete="name"
                  v-model.trim="form.ar.name"
                  invalid-feedback="يوجد خطأ في هذا الحقل"
                  :is-valid="!$v.form.ar.name.$anyError"
                />
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol sm="12" md="9">
                <CInput
                  invalid
                  description=""
                  label="الاسم الانجليزي"
                  horizontal
                  autocomplete="name"
                  v-model.trim="form.en.name"
                  invalid-feedback="يوجد خطأ في هذا الحقل"
                  :is-valid="!$v.form.en.name.$anyError"
                />
              </CCol>
            </CRow>
            
            
            <CRow class="mt-3">
              <CCol sm="12" md="9">
                <CTextarea
                  invalid
                  description=""
                  label="الوصف العربي"
                  horizontal
                  autocomplete="name"
                  v-model.trim="form.ar.description"
                  invalid-feedback="يوجد خطأ في هذا الحقل"
                  :is-valid="!$v.form.ar.description.$anyError"
                />
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol sm="12" md="9">
                <CTextarea
                  invalid
                  description=""
                  label="الوصف الانجليزي"
                  horizontal
                  autocomplete="name"
                  v-model.trim="form.en.description"
                  invalid-feedback="يوجد خطأ في هذا الحقل"
                  :is-valid="!$v.form.en.description.$anyError"
                />
              </CCol>
            </CRow>

            <CRow class="mt-5">
              <CCol sm="12" md="9">
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> حالة التصنيف </label>
                  <CCol sm="9">
                    <div role="group" class="form-check form-check-inline">
                      <label>
                        <input
                          type="radio"
                          name="category_id"
                          class="form-check-input"
                          value="1"
                          v-model="form.status"
                        />
                        نشط
                      </label>
                    </div>

                    <div role="group" class="form-check form-check-inline">
                      <label>
                        <input
                          type="radio"
                          name="category_id"
                          class="form-check-input"
                          value="0"
                          v-model="form.status"
                        />
                        متوقف
                      </label>
                    </div>
                  </CCol>
                </div>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
        <CCardFooter>
          <CButton
            :disabled="loading"
            @click="submit"
            type="button"
            color="success"
          >
            <CSpinner v-if="loading" color="white" size="sm" />
            <span v-else> <CIcon name="cil-save" /> حفظ </span>
          </CButton>
        </CCardFooter>
        <CCardFooter v-if="formErrors">
          <CAlert color="danger">
            <CListGroup flush>
              <CListGroupItem v-for="(err, index) in formErrors" :key="index">
                {{ err[0] }}
              </CListGroupItem>
            </CListGroup>
          </CAlert>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "Create",
  data() {
    return {
      loading: false,
      fileRecords: [],
      fileRecordsForUpload: [],
      formErrors: null,
      form: {
        ar: {
          name: "",
          description: ""
        },
        en: {
          name: "",
          description: ""
        },
        status: "1",
      },
    };
  },
  validations: {
    form: {
      ar: {
        name: { required, min: minLength(2), max: maxLength(200) },
        description: { min: minLength(15) },
      },
      en: {
        name: { required, min: minLength(2), max: maxLength(200) },
        description: { min: minLength(15) },
      },
      status: { required }
    },
  },
  created() {},
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return true;
      }
      this.loading = true;
      
      this.$http
        .post(`/categories`, this.form)
        .then((res) => {
          // handle success
          if (res.data.status == 202) {
            this.$router.push({ name: "Categories" });
          }
          this.loading = false;
        })
        .catch((error) => {
          // handle error
          this.loading = false;
          if ((error.response.data.status == 422)) {
            this.formErrors = error.response.data.error.errors;
          }
        });
    }
  },
};
</script>
